import React from "react";
import AppCard from "../components/inLine/AppCard";
import apps from "../config/AppsData";
import styles from "./DownloadPage.module.css";

const DownloadPage = () => {
  return (
    <div className={"inline-section"}>
      <div className={"inline-banner"}>
        <img src="/images/BannerDownload.jpg" alt="Download Banner" className={"bannerImage"} />
        <h1 className="slide-in">Download</h1>
      </div>
      <div className={"inline-content"}>
        <div className={styles.downloadPage}>
          <p>
            Choose the <em>app</em> that's right for you and get started today!
          </p>
          <div className={styles.appsContainer}>
            {apps.map(app => (
              <AppCard key={app.id} app={app} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;

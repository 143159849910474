import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { useToast } from "./ToastContext";
import { settings } from "../config/Settings";
import { useAppContext } from "./AppContext";
import { Log } from "../services/LogService";

const logger = Log("WebSocketContext");
const WebSocketContext = createContext({ isConnected: false, sendMessage: () => {}, sendLocation: () => {} });

export const useWebSocket = () => {
  return useContext(WebSocketContext);
};

export const WebSocketProvider = ({ children, appName }) => {
  const socket = useRef(null);
  const [isConnected, setIsConnected] = useState(false);
  const { Toast } = useToast();
  const {
    webSocketService: { setPortfolioEvent, setPortfolioListEvent },
  } = useAppContext();

  useEffect(() => {
    // Initialize socket connection
    socket.current = io(settings.baseApiUrl);

    // Handle connection
    socket.current.on("connect", () => {
      setIsConnected(true);
      // Register the connection with application name
      socket.current.emit("register", { appName, XApiKey: settings.apiKey });
    });

    // Handle disconnection
    socket.current.on("disconnect", () => {
      setIsConnected(false);
    });

    // Handle receive_message event
    socket.current.on("receive_message", item => {
      logger.debug("receive_message", JSON.stringify(item));
      if (settings.broadcastIgnoredPathnames.some(path => window.location.pathname.startsWith(path))) return;
      if (item.objectId === 1) {
        let { message } = item.data;
        Toast.broadcast(message);
      }
    });

    // Handle refresh_portfolioList event
    socket.current.on("refresh_portfolioList", item => {
      logger.debug(`received refresh_portfolioList [${JSON.stringify(item)}]`);
      setPortfolioListEvent(item);
    });

    // Handle refresh_portfolioList event
    socket.current.on("refresh_portfolio", item => {
      logger.debug(`received refresh_portfolio [${JSON.stringify(item)}]`);
      setPortfolioEvent({ action: "refresh", data: item });
    });

    return () => {
      // Clean up socket connection on unmount
      socket.current.disconnect();
    };
  }, [appName]);

  const sendMessage = message => {
    if (socket.current && isConnected) {
      socket.current.emit("send_message", message);
    }
  };

  const sendLocation = (location, portfolioId) => {
    if (socket.current && isConnected && ["portfolio", "in-line"].includes(location)) {
      logger.debug(`send_location ${JSON.stringify({ location, portfolioId })}`);
      socket.current.emit("send_location", { location, portfolioId });
    }
  };

  return <WebSocketContext.Provider value={{ isConnected, sendMessage, sendLocation }}>{children}</WebSocketContext.Provider>;
};

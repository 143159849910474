const apps = [
  {
    id: 1,
    name: "My Portfolio",
    description: `
      Discover an innovative application that allows you to easily create a professional and personalized portfolio. 
      Whether you are a student, creative, or professional seeking new opportunities, this application provides all the necessary tools to showcase your skills and achievements.`,
    appStoreLink: "https://apps.apple.com/app/id6738900138",
    screenshot: "/images/apps/app-portfolio-screenshot-iphone.jpg",
  },
];

export default apps;

import { useEffect, useRef, useState } from "react";
import { FetchService } from "../services/FetchService";
import { Log } from "../services/LogService";

const logger = Log("UseEntityLock");

const useEntityLock = (entityType, entityId, onLockFailure = () => {}) => {
  const [isLocked, setIsLocked] = useState(false);
  const lockAcquired = useRef(false);

  const lockEntity = async () => {
    if (!entityId || !entityType) return;
    try {
      await FetchService().lockEntity(entityType, entityId);
      logger.debug(`Successfully locked entity: ${entityType}, ID: ${entityId}`);
      setIsLocked(true);
      lockAcquired.current = true;
    } catch (error) {
      logger.debug("Failed to lock the entity:", error);
      onLockFailure();
    }
  };

  const unlockEntity = async () => {
    if (!lockAcquired.current) return; // Only unlock if it was locked
    try {
      await FetchService().unlockEntity(entityType, entityId);
      logger.debug(`Unlocking entity: ${entityType}, ID: ${entityId}`);
      setIsLocked(false);
    } catch (error) {
      logger.warn("Failed to unlock the entity:", error);
    }
  };

  useEffect(() => {
    lockEntity();
    window.addEventListener("beforeunload", unlockEntity);
    return () => {
      unlockEntity();
      window.removeEventListener("beforeunload", unlockEntity);
    };
  }, [entityType, entityId]);

  return { isLocked, unlock: unlockEntity };
};

export default useEntityLock;

import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import styles from "./AppCard.module.css";

const AppCard = ({ app }) => {
  return (
    <div className={styles.appCard}>
      <img src={app.screenshot} alt={`${app.name} Screenshot`} className={styles.appScreenshot} />
      <div className={styles.appContent}>
        <h3>{app.name}</h3>
        <p>{app.description}</p>
        <div className={styles.appActions}>
          <a href={app.appStoreLink} target="_blank" rel="noopener noreferrer">
            <img src="/images/apps/apple-store-badge.png" alt="Download from the App Store" className={styles.appStoreBadge} />
          </a>
          <QRCodeCanvas value={app.appStoreLink} size={80} />
        </div>
      </div>
    </div>
  );
};

export default AppCard;

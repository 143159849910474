import React from "react";
import styles from "./LegalNotices.module.css";
import { FaRegFileLines, FaMagnifyingGlass } from "react-icons/fa6";

const LegalNotices = () => {
  return (
    <div className={"inline-section"}>
      <div className={"inline-banner"}>
        <img src="/images/BannerLegal.jpg" alt="Legal Banner" className={"bannerImage"} />
        <h1 className="slide-in">Legal Notices</h1>
      </div>
      <div className={`inline-content ${styles.legalNotices}`}>
        <section id="terms-of-service">
          <div className={styles.noticeTitle}>
            <FaRegFileLines className={styles.icon} />
            <h2>Terms of Service</h2>
          </div>
          <p>By using our application, you agree to the following terms:</p>
          <ul>
            <li>
              You are <em>responsible</em> for all content you publish via our service.
            </li>
            <li>You must not upload illegal or harmful content.</li>
          </ul>
          <p>These terms may be updated at any time. Thank you for using our service responsibly.</p>
        </section>
        <section id="privacy-policy" style={{ marginTop: "40px" }}>
          <div className={styles.noticeTitle}>
            <FaMagnifyingGlass className={styles.icon} />
            <h2>Privacy Policy</h2>
          </div>
          <p>
            We collect user data, such as names, email addresses, and portfolio content. These data are used solely to provide and <em>improve</em> our services. You may request the deletion or correction of your data at any time.
          </p>
          <p>By using our application, you agree to this privacy policy.</p>
          <p>
            If you have any questions, feel free to contact us at
            <a href="mailto:contact@in-line.fr"> contact@in-line.fr</a>
          </p>
        </section>
      </div>
    </div>
  );
};

export default LegalNotices;
